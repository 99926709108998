

















.logo {
  align-items: center;
  text-align: center;
  gap: 3em;
  margin-bottom: 1em;
}
